import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AvantLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Parekattil and Dr. Galante"
    institution="Avant"
    referralCode="AVANT"
    multiplePhysicians={true}
  />
)

export default AvantLandingPage
